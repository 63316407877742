import React, { useState, useEffect } from "react";
import "./CookiePopup.styles.scss";

const Popup = ({ onAccept, onDecline }) => {
  const [showPopup, setShowPopup] = useState(false);
  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  };
  useEffect(() => {
    const hasAcceptedCookies = getCookie("acceptedCookiesPopup");

    if (!hasAcceptedCookies) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleAccept = () => {
    setCookie("acceptedCookiesPopup", "true", 365); // Save the acceptance as a cookie for 1 year
    onAccept();
    handleClose();
  };

  const handleDecline = () => {
    // localStorage.setItem("acceptedCookies", "false");
    onDecline();
    handleClose();
  };

  return (
    <div className={`popup-container ${showPopup ? "visible" : ""}`}>
      <div className="popup-content">
        <p>We use cookies to enhance your experience. Do you accept cookies?</p>
        <button className="accept" onClick={handleAccept}>
          Accept
        </button>
        <button className="decline" onClick={handleDecline}>
          Decline
        </button>
      </div>
      {showPopup && <div className="overlay" onClick={handleClose}></div>}
    </div>
  );
};

const CookiePopup = () => {
  const handleAccept = () => {
    // Handle cookie acceptance logic here
    
  };

  const handleDecline = () => {
    // Handle cookie decline logic here
  };

  return (
    <div className="app-container">
      <Popup onAccept={handleAccept} onDecline={handleDecline} />
    </div>
  );
};

export default CookiePopup;
