export const HeaderData = {
  header: {
    phrase: "Personalized Quality Education At Unbeatable Pricing",
    highlight: "Unbeatable",
  },
  paragraph: {
    phrase:
      "Are you ready to start your education path? EDUCIFY is here for you",
    highlight: "EDUCIFY",
  },
  placeholder: "What do you want to learn ?",
};
