import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./SharedLayout.styles.scss";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import firebase from "firebase/compat/app";

import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

import rectangle367 from "../../../assets/SVG/rectangle367.svg";
import group1742 from "../../../assets/SVG/group1742.svg";

import reboost from "../../../assets/SharedApp/images/reboost-dark.png";
import Menu from "../Menu/Menu";

import { resetCourseState } from "../../../ReduxToolkit/Slices/coursesSlice";
import {
  getContacts,
  getMessageUnseenCount,
} from "../../../ReduxToolkit/Slices/userSlice";
import {
  getTeacherNotificationsNumber,
  handleResetLessonIndex,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import {
  getStudentAdminBookings,
  getStudentNotificationsNumber,
} from "../../../ReduxToolkit/Slices/studentSlice";
import { getTeacherBookingsByStatus } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { getAdminNotificationsNumber } from "../../../ReduxToolkit/Slices/adminSlice";
import {
  AdminInitial,
  BlogAdminInitial,
  itemsInitialState,
  studentsInitial,
  studentSteps,
} from "./DashboardItems";
import { steps } from "framer-motion";

const SharedLayout = () => {
  let params = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, refreshMessages } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.student);
  const { teacher, refreshRequests } = useSelector((state) => state.teacher);
  const { showCourses } = useSelector((state) => state.user);
  let newparms = params?.length ? params.pathname.split("/")[2] : "";

  const [items, setItems] = useState(studentsInitial);
  const [loaded, setLoaded] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [teachBookings, setTeachBookings] = useState(0);
  const [currentSteps, setCurrentSteps] = useState([]);

  let location = params.pathname
    .slice(1, params.pathname?.length)
    .split("/")[0];
  useLayoutEffect(() => {
    if (location === "blogadmin" || user?.role?.toUpperCase() === "BLOGADMIN") {
      if (showCourses) {
        setItems(itemsInitialState);
      } else {
        setItems(
          BlogAdminInitial.filter(
            (el) => !el.title.toLowerCase().includes("course")
          )
        );
      }
      setLoaded(true);
    } else if (location === "admin") {
      setItems(AdminInitial);
      setLoaded(true);
    } else if (location === "students") {
      if (!showCourses) {
        setItems(
          studentsInitial.filter(
            (el) => !el.title.toLowerCase().includes("course")
          )
        );
      } else {
        setItems(studentsInitial);
      }
      setLoaded(true);
    } else if (location === "teachers") {
      if (showCourses) {
        setItems(itemsInitialState);
      } else {
        setItems(
          itemsInitialState.filter(
            (el) => !el.title.toLowerCase().includes("course")
          )
        );
      }
      setLoaded(true);
    }
  }, [params]);

  const title = items.filter((element) => {
    if (newparms?.length > 0) {
      return element.link === newparms;
    } else return [];
  });

  let lastParam = params.pathname.split("/");
  lastParam = lastParam[lastParam.length - 1];
  let goodParms = params.pathname.split("/")[2];
  const userType = params.pathname.split("/")[1];

  const navigateOption = (a, b, c) => {
    if (b.includes("courses/create")) {
      dispatch(resetCourseState());
    } else {
    }
    if (c) {
      navigate(`/${a}/${b}`);
    } else {
      navigate(`/${a}${b}`);
    }
  };

  const [messages, setMessages] = useState(0);
  const [showGuide, setShowGuide] = useState(true);
  const [bookings, setBookings] = useState();

  useEffect(() => {
    if (user?.role === "TEACHER") {
      dispatch(
        getTeacherBookingsByStatus({
          teacherId: teacher.id,
          status: "PENDING",
        })
      )
        .then((res) => {
          let bookings = res?.payload?.data;
          if (bookings?.length) {
            let newBookings = bookings.filter((el) => el.bookings.length > 0);
            setTeachBookings(newBookings.length ? newBookings.length : 0);
          }
        })
        .catch((er) => console.log("error bookings", er));
    }
  }, [user?.role, refreshRequests]);

  useEffect(() => {
    if (user?.role === "TEACHER" || user?.role === "STUDENT") {
      dispatch(getMessageUnseenCount()).then((res) => {
        if (res.type === "getUserChatMessages/fulfilled") {
          setMessages(res.payload);
        }
      });
    }
  });
  useEffect(() => {
    if (user.role === "TEACHER") {
      dispatch(getTeacherNotificationsNumber(teacher.id)).then((res) =>
        setNotifications(res.payload)
      );
    }
    if (user.role === "STUDENT") {
      dispatch(getStudentNotificationsNumber(student)).then((res) => {
        {
          setNotifications(res.payload);
        }
      });
      dispatch(getStudentAdminBookings()).then((res) => {
        if (res.type.includes("fulfilled")) {
          setBookings(res.payload);
        }
      });
    }
    if (user.role === "ADMIN") {
      dispatch(getAdminNotificationsNumber()).then((res) => {
        setNotifications(res.payload);
      });
    }
  }, []);
  useEffect(() => {
    if (params.pathname.split("/")[1].toLowerCase() === "teachers")
      setCurrentSteps(steps);
    else if (params.pathname.split("/")[1].toLowerCase() === "students")
      setCurrentSteps(studentSteps);
    else setCurrentSteps([]);
  }, [params.pathname]);

  const handleResetLesson = () => {
    dispatch(handleResetLessonIndex(-1));
  };

  return (
    <div>
      {showGuide && params.pathname === "/students" && (
        <Steps
          enabled={user.helpGuide}
          steps={currentSteps}
          initialStep={0}
          onExit={() => setShowGuide(false)}
          options={{ exitOnOverlayClick: false }}
        />
      )}
      <div className="SharedLayout_Container">
        <div className="SharedLayout_Subcontainer">
          <Link to="/" className="group-1742">
            <img src={group1742} />
          </Link>
          {items &&
            items.map((item, i) => (
              <>
                <a
                  id={`guide${i}`}
                  onClick={() => {
                    if (item.title === "My Listings") {
                      handleResetLesson();
                    }
                    item.openNewPage
                      ? window.open(item.link, "_blank")
                      : navigateOption(location, item.link, true);
                    item.link === "notifications" && setNotifications(0);
                  }}
                  className={`side_dash_nav ${
                    goodParms &&
                    goodParms.includes(
                      item.link.split("/")[0] ? item.link.split("/")[0] : ""
                    )
                      ? "item-1"
                      : "flex-container"
                  }`}
                >
                  {item.link.includes(goodParms) && goodParms !== "" && (
                    <img className="frame" src={rectangle367} />
                  )}
                  {item.link === "" && goodParms === "" && (
                    <img className="frame" src={rectangle367} />
                  )}
                  {/* {goodParms === item.link && (
                    <img className="frame" src={rectangle367} />
                  )} */}
                  <img className="icons" src={item.icon} />
                  <a className="titles">{item.title}</a>
                  {item.link === "mymessages" && messages ? (
                    <p className="count_messages flex_center">{messages}</p>
                  ) : null}
                  {item.link === "notifications" &&
                  notifications &&
                  notifications > 0 ? (
                    <p className="count_messages flex_center">
                      {notifications}
                    </p>
                  ) : null}
                  {item.link === "myrequests" &&
                  teachBookings &&
                  teachBookings > 0 ? (
                    <p className="count_messages flex_center">
                      {teachBookings}
                    </p>
                  ) : null}
                  {item.link === "admin-booking" && bookings?.length ? (
                    <p className="count_messages flex_center">
                      {bookings?.length}
                    </p>
                  ) : (
                    ""
                  )}
                </a>
                <div className="settings__list">
                  {item.options &&
                    item.options.map((el) => (
                      <li
                        className={`${el?.className} ${
                          el.link.split("/")[2] == lastParam
                            ? "selected__list"
                            : ""
                        }`}
                      >
                        <a
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => navigateOption(userType, el.link)}
                        >
                          -{el.name}{" "}
                          {bookings?.length >= 1 &&
                            el.name.toLowerCase() === "pending invoices" && (
                              <span className="notification_number">
                                {bookings?.length}
                              </span>
                            )}
                        </a>
                      </li>
                    ))}
                </div>
              </>
            ))}
          
        </div>
        <div className="Outlet_Container">
          <Menu
            title={newparms?.length > 0 ? title?.length && title[0].title : ""}
          />
          <div
            className={
              params.pathname.includes("viewCourse") ? "outlet full" : "outlet"
            }
          >
            <div className="site_location">
              <h2>
                {newparms?.length > 0 ? title?.length && title[0].title : ""}
              </h2>
              {/* <p>
                Home -
                {newparms.length > 0
                  ? title.length && title[0].title
                  : "Dashboard"}
              </p> */}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SharedLayout;
