import React, { useState } from "react";
import "./header.styles.scss";
import kickstart from "../../assets/HomeApp/images/kickstart.webp";
import searchIcon from "../../assets/HomeApp/svgs/search.svg";
import { HeaderData } from "../../assets/HomeApp/data/header-data";
import header from "../../assets/HomeApp/images/header2.png";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const [searchValue, setSearch] = useState("");
  const navigate = useNavigate();

  return (
    <div className="header__container">
      <div className="header__phrase">
        <div className="header__img">
          <img src={kickstart} alt="kickstart" className="kickstart" />
        </div>
        <h1 className="subtitle">
          {HeaderData.header.phrase.split(HeaderData.header.highlight)[0]}{" "}
          <span className="highlighted">{HeaderData.header.highlight}</span>
          {HeaderData.header.phrase.split(HeaderData.header.highlight)[1]}
        </h1>
        <p onClick={() => navigate("/teacher-signup")} className="pointer">
          <span> Click here to join </span>
          <span className="highlighted">Educify </span>
          <span>as a teacher</span>
        </p>

        {/* Add the "Free Trial" button */}
        <button
          onClick={() => navigate("/Free-Trial")}
          style={{
            backgroundColor: "#FFD700", // Deep yellow color
            color: "#000", // White text
            padding: "10px 20px", // Adjust padding for better fit
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "18px", // Increased font size for larger text
            cursor: "pointer",
            marginTop: "20px",
            textAlign: "center", // Center-align text
            width: "200px", // Set a fixed width for consistency
          }}
        >
          Book a Free Trial
        </button>
      </div>

      <div className="header__bg__image">
        <img src={header} alt="header" />
      </div>
      <form
        className="header__inputs__mobile"
        onSubmit={() => navigate(`/tutors/search/${searchValue}`)}
      >
        <input
          type="text"
          placeholder={HeaderData.placeholder}
          value={searchValue}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit">
          <img src={searchIcon} alt="search" />
        </button>
      </form>
    </div>
  );
}
