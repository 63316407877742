import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import BookingInput from "../../Components/BookingInput/BookingInput";
import SharedModalContainer from "../Booking/SharedModalContainer";
import DateTimeInput from "../../Components/DateTimeInput/DateTimeInput";
import searchClient from "../../../assets/AdminApp/svgs/adminBookingIcon1.svg";
// import searchLesson from "../../../assets/AdminApp/svgs/adminBookingIcon2.svg";
import selectArrowDown from "../../../assets/AdminApp/svgs/select_arrow_down.svg";
import Toggle from "react-toggle";
import Select from "react-select";
import { TimezoneSelector } from "../../../hooks";
import del from "../../../assets/SharedApp/svgs/Button Trash.svg";

import { getSubjects } from "../../../ReduxToolkit/Slices/lessonsSlice";
import {
  bookLessonByAdmin,
  getFilteredStudents,
  getFilteredTeachers,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { getLessonPackages } from "../../../ReduxToolkit/Slices/paymentSlice";
import {
  // convertCustomDateToUTC,
  convertDateTimeToUtc,
  ConvertFromTimeZoneToTimeZone,
  convertTimeToTimezone,
  filterPastTimes,
  getSortableTime,
  timesCustomStyles,
} from "./helpers";
import { toast } from "react-toastify";
import { format, isBefore } from "date-fns";
const CreateBookingModal = ({
  isOpen,
  closeModal,
  customStyles,
  // onSubmit,
  onIconClick,
  studentToCreate,
  isCreatingStudent,
  setShowConfirmBooking,
  setBookingInfo,
  setIsCreatingStudent,
}) => {
  const dateRef = useRef();
  const timeRef = useRef();
  const dispatch = useDispatch();
  const handleButtonClick = () => setMenuIsOpen(true);
  const handleMenuClose = () => setMenuIsOpen(false);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuTwoIsOpen, setMenuTwoIsOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  const [teacherSearchInput, setTeacherSearchInput] = useState("");
  const [subjectSearchInput, setSubjectSearchInput] = useState("");
  const [isTeacherLoading, setIsTeacherLoading] = useState(false);
  const [teacherLessons, setTeacherLessons] = useState([]);
  const [lessonLocations, setLessonLocations] = useState([]);
  const [selectedTeacherLesson, setSelectedTeacherLesson] = useState();
  const [packages, setPackages] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedAvailabilities, setSelectedAvailabilities] = useState({});
  const [mappedAvailabilities, setMappedAvailabilities] = useState([]);
  const [selectedTime, setSelectedTime] = useState();
  const [isRecurring, setIsRecurring] = useState(false);
  const [flexibleDates, setFlexibleDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBookingType, setSelectedBookingType] = useState({
    value: "WEEKLY",
    label: "Weekly",
  });
  const { timezone } = useSelector((state) => state.user);
  const handleChooseFlexibleDate = (date) => {
    if (selectedBookingType?.value === "WEEKLY") {
      setSelectedTime(date);
    } else {
      if (selectedPackage?.info?.numOfLessons > flexibleDates.length) {
        const foundDate = flexibleDates.find(
          (el) => el.label === `${selectedDate} at ${date.label}`
        );
        if (foundDate) {
          return toast.warning("Date already added");
        } else
          setFlexibleDates([
            ...flexibleDates,
            {
              label: `${selectedDate} at ${date.label}`,
              value: `${selectedDate} ${date.value}`,
            },
          ]);
      } else {
        toast.error(
          `You cant add more than ${selectedPackage?.info?.numOfLessons} lessons for this package`
        );
      }
    }
  };
  const onDeleteFlexDate = (date) => {
    const filteredDates = flexibleDates.filter((el) => el.label !== date.label);
    setFlexibleDates(filteredDates);
  };

  const getFilteredLessons = (lessons, subject) => {
    // console.log("lessons", lessons[0].subjects);
    if (!subject) {
      return lessons;
    } else
      return lessons.filter((lesson) => lesson?.subjects[0]?.name === subject);
  };

  useEffect(() => {
    dispatch(getFilteredStudents({ limit: "10000" })).then((res) => {
      if (res.type.includes("fulfilled")) {
        const studentOptions = res.payload.students.map((student) => ({
          value: student?.user?.id,
          label: `${student?.user?.name} ${student?.user.lastName}`,
          icon: student?.user?.profileImage?.url,
        }));
        setStudents(studentOptions);
      }
    });
  }, []);

  useEffect(() => {
    setIsTeacherLoading(true);
    dispatch(
      getFilteredTeachers({
        limit: "50",
        status: "APPROVED",
        search: teacherSearchInput,
        subject: selectedSubject?.value ? selectedSubject?.value : "",
      })
    ).then((res) => {
      console.log("wtfffffffffffff", res);
      if (res.type.includes("fulfilled")) {
        const teacherOptions = res.payload.teachers.map((teacher) => ({
          value: teacher?.id,
          label: `${teacher?.user?.name} ${teacher?.user.lastName}`,
          icon: teacher?.user?.profileImage?.url,
          info: {
            ...teacher,
            lessons: getFilteredLessons(
              teacher.lessons,
              selectedSubject?.value
            ),
          },
        }));
        setTeachers(teacherOptions);
      }
      setIsTeacherLoading(false);
    });
    console.log("teacherrrrrrrss", teachers);
  }, [teacherSearchInput, selectedSubject]);

  useEffect(() => {
    dispatch(getSubjects({ search: subjectSearchInput })).then((res) => {
      if (res.type.includes("fulfilled")) {
        setSubjects(
          res.payload?.data?.subjects.map((el) => {
            return {
              label: el.name,
              value: el.name,
            };
          })
        );
      }
    });
  }, [subjectSearchInput]);

  const onTeacherSearch = (searchValue) => {
    // Function to handle searching/filtering tutors
    const filteredTutors = teachers.filter((teacher) =>
      teacher.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setTeachers(filteredTutors);
  };

  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
    setSelectedTime();
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const day = dayNames[date.getDay()];
    const filteredAvailabilities = availabilities.filter(
      (av) => av.day === day.toUpperCase()
    );

    // Update selectedAvailabilities state here
    setSelectedAvailabilities(filteredAvailabilities[0] || {});
  };

  useEffect(() => {
    if (selectedLocation && selectedTeacherLesson?.value)
      dispatch(
        getLessonPackages({
          selectedPlace: selectedLocation,
          id: selectedTeacherLesson?.value,
        })
      ).then((res) => {
        setPackages(
          res.payload?.map((pack) => {
            return {
              value: pack?.id,
              label: `${pack?.name} - ${pack.duration}min - ${pack.numOfLessons} Lesson - ${pack.percentage}% off`,
              info: pack,
            };
          })
        );
      });
  }, [selectedLocation]);

  useEffect(() => {
    if (
      selectedAvailabilities?.times &&
      selectedAvailabilities?.times?.length
    ) {
      setMappedAvailabilities(
        filterPastTimes(
          formatTimes(selectedAvailabilities.times).sort(
            (a, b) => getSortableTime(a.label) - getSortableTime(b.label)
          ),
          selectedDate
        )
      );
    }
    if (
      selectedAvailabilities?.times &&
      selectedAvailabilities?.times?.length === 0
    ) {
      setMappedAvailabilities([]);
    }
  }, [selectedAvailabilities, timezone]);

  useEffect(() => {
    if (isCreatingStudent) {
      const { name, lastName, email, city, country, phone } = studentToCreate;
      if (name && lastName && email && city && country && phone) {
        setSelectedStudent({
          value: "",
          label: `${name} ${lastName}`,
          icon: "",
        });
      }
    } else {
      setSelectedStudent();
    }
  }, [isCreatingStudent, studentToCreate]);

  const formatOptionLabel = ({ label, icon }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={icon}
          alt=""
          style={{ width: 20, height: 20, marginRight: 10 }}
        />
        {label}
      </div>
    );
  };
  const formatTimes = (array) => {
    if (array?.length)
      return array.map((item) => {
        const startTime = moment(item.startTime).format("h:mm a");
        return {
          value: startTime,
          label: convertTimeToTimezone(startTime, timezone),
        };
      });
  };

  const BookLessonByAdmin = () => {
    if (!isCreatingStudent && !selectedStudent?.value)
      return toast.warning("Missing student");
    if (isCreatingStudent) {
      const { name, lastName, email, city, country, phone } = studentToCreate;
      if (!name) return toast.warning("Missing student name");
      if (!lastName) return toast.warning("Missing student last name");
      if (!email) return toast.warning("Missing student email");
      if (!phone) return toast.warning("Missing student phone");
      if (!city) return toast.warning("Missing student city");
      if (!country) return toast.warning("Missing student country");
    }
    if (!selectedTeacher?.value) return toast.warning("Missing teacher");
    if (!selectedTeacherLesson?.value) return toast.warning("Missing Lesson");
    if (!selectedLocation?.value) return toast.warning("Missing Location");
    if (!selectedPackage?.value) return toast.warning("Missing package");
    if (!selectedDate) return toast.warning("Missing Date");
    if (!selectedTime?.value && selectedBookingType.value === "WEEKLY")
      return toast.warning("Missing time");
    if (
      selectedBookingType.value === "FLEXIBLE" &&
      selectedPackage?.info?.numOfLessons > flexibleDates.length
    )
      return toast.warning(
        `You need to add ${
          selectedPackage?.info?.numOfLessons - flexibleDates.length
        } more date`
      );
    const studentToCreateObj = {
      role: "STUDENT",
      name: studentToCreate?.name,
      lastName: studentToCreate?.lastName,
      email: studentToCreate?.email,
      password: undefined,
      address: {
        street: "",
        city: studentToCreate?.city,
        state: "",
        country: studentToCreate?.country,
      },
      phone: studentToCreate?.phone,
      profileImage: {
        url: "",
        publicId: "",
      },
      geoLocation: studentToCreate?.geoLocation,
      // nationality: "",
    };
    const flexibleAppointments = flexibleDates.map((el) => {
      // Extract date and time from the label
      const [date, time12hr] = el.label.split(" at ");

      return {
        startTime: ConvertFromTimeZoneToTimeZone(
          timezone,
          date,
          time12hr, // Use time directly from the label
          "UTC"
        ),
      };
    });

    const bookingObj = {
      booking: {
        teacherId: selectedTeacher?.value,
        lessonId: selectedTeacherLesson?.value,
        location: selectedLocation?.value,
        packageId: selectedPackage?.value,
        weeklyAppointments: {
          // startTime: convertDateTimeToUtc({
          //   date: selectedDate,
          //   time: selectedTime?.value,
          //   timezone: "UTC",
          // }),
          startTime: ConvertFromTimeZoneToTimeZone(
            timezone,
            selectedDate,
            selectedTime?.label,
            "UTC"
          ),
        },
        flexibleAppointments,
        subscription: isRecurring ? true : false,
        type: selectedBookingType?.value,
      },
      userId: selectedStudent?.value,
      user: studentToCreateObj,
    };
    if (isCreatingStudent) {
      delete bookingObj.userId;
    } else {
      delete bookingObj.user;
    }
    if (selectedBookingType.value === "WEEKLY") {
      delete bookingObj?.flexibleAppointments;
    } else {
      delete bookingObj?.weeklyAppointments;
    }
    setIsLoading(true);
    dispatch(bookLessonByAdmin(bookingObj)).then((res) => {
      if (res.type.includes("fulfilled")) {
        toast.success("Booking Successful");
        setBookingInfo({
          ...bookingObj,
          flexibleDates,
          weeklyDate: { date: selectedDate, time: selectedTime?.value },
          selectedTeacher,
          selectedStudent,
          selectedPackage,
          selectedLocation,
          selectedTeacherLesson,
        });
        setShowConfirmBooking(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(`Something went wrong, reason: ${res?.payload?.error}`);
      }
    });
  };
  // const filterPastTimes = (options) => {
  //   const now = new Date();

  //   return options.filter((option) => {
  //     const optionTime = new Date(option.value); // Assuming your option.value is a date string or timestamp
  //     return !isBefore(optionTime, now);
  //   });
  // };
  const getUniqueTimes = (times) => {
    const uniqueTimes = new Set();
    return times.filter((time) => {
      const isDuplicate = uniqueTimes.has(time.label);
      uniqueTimes.add(time.label);
      return !isDuplicate;
    });
  };
  const availableTimes = getUniqueTimes(filterPastTimes(mappedAvailabilities));
  return (
    <SharedModalContainer
      title={"Create booking"}
      isOpen={isOpen}
      onClose={closeModal}
      onCancel={closeModal}
      onSubmit={BookLessonByAdmin}
      isLoading={isLoading}
      key={isOpen}
    >
      <div className="select_timezone">
        <TimezoneSelector />
      </div>
      <div className="booking_inputs">
        <BookingInput
          label="Client"
          options={students}
          value={selectedStudent}
          onChange={(val) => {
            setIsCreatingStudent(false);
            setSelectedStudent(val);
          }}
          key={selectedStudent?.value}
          customStyles={customStyles}
          placeholder="Select client..."
          menuIsOpen={menuIsOpen}
          handleButtonClick={handleButtonClick}
          handleMenuClose={handleMenuClose}
          icon={searchClient}
          onIconClick={onIconClick}
          formatOptionLabel={formatOptionLabel}
        />
        <BookingInput
          label="Subject (optional)"
          options={subjects}
          value={selectedSubject}
          onChange={(val) => {
            setSelectedTeacher();
            setSelectedTeacherLesson();
            setSelectedSubject(val);
          }}
          key={selectedSubject?.value}
          customStyles={customStyles}
          placeholder="Filter tutors by subject"
          // menuIsOpen={menuIsOpen}
          // handleButtonClick={handleButtonClick}
          // handleMenuClose={handleMenuClose}
          // icon={searchClient}
          onSearch={(val) => {
            setSubjectSearchInput(val);
          }}
          // onIconClick={onIconClick}
          formatOptionLabel={formatOptionLabel}
        />
        <BookingInput
          label="Tutor"
          options={teachers}
          value={selectedTeacher}
          key={selectedTeacher?.value}
          customStyles={customStyles}
          placeholder="Select tutor..."
          isSearchable // Add this prop to enable typing for search
          isLoading={isTeacherLoading}
          disabled={isTeacherLoading}
          onInputChange={onTeacherSearch} // Pass the search function here
          formatOptionLabel={formatOptionLabel}
          onChange={(val) => {
            setAvailabilities(val?.info?.availability);
            setSelectedTeacher(val);
            setTeacherLessons(
              val?.info?.lessons?.map((lesson) => ({
                value: lesson?.id,
                label: `${lesson?.title}`,
                info: lesson,
              }))
            );
          }}
        />
        <BookingInput
          label="Lesson"
          options={teacherLessons}
          value={selectedTeacherLesson}
          onChange={(val) => {
            setSelectedTeacherLesson(val);
            setLessonLocations(
              val?.info?.location?.map((location) => {
                return {
                  label: location,
                  value: location,
                };
              })
            );
          }}
          customStyles={customStyles}
          placeholder="Select lesson..."
          menuIsOpen={menuTwoIsOpen}
          handleButtonClick={() => setMenuTwoIsOpen(true)}
          handleMenuClose={() => setMenuTwoIsOpen(false)}
          // icon={searchLesson}
          disabled={!teacherLessons?.length}
        />
        <BookingInput
          label="Location"
          options={lessonLocations}
          value={selectedLocation}
          onChange={(val) => setSelectedLocation(val)}
          customStyles={customStyles}
          placeholder="Select location..."
          icon={selectArrowDown}
          imgWithMargin
          disabled={!lessonLocations?.length}
        />
        <BookingInput
          label="Lesson Package"
          options={packages}
          value={selectedPackage}
          onChange={(val) => setSelectedPackage(val)}
          customStyles={customStyles}
          placeholder="Select package..."
          icon={selectArrowDown}
          imgWithMargin
          disabled={!packages.length}
        />
        <BookingInput
          label="Lesson Type"
          options={[
            { value: "WEEKLY", label: "Weekly" },
            { value: "FLEXIBLE", label: "Flexible" },
          ]}
          value={selectedBookingType}
          onChange={(val) => setSelectedBookingType(val)}
          customStyles={customStyles}
          placeholder="Select booking type..."
          icon={selectArrowDown}
          imgWithMargin
          disabled={!packages.length}
        />
        <DateTimeInput
          dateRef={dateRef}
          timeRef={timeRef}
          value={selectedDate}
          onChange={handleDateChange}
          disabled={!teacherLessons?.length}
        >
          <Select
            styles={timesCustomStyles}
            placeholder="Select times..."
            value={selectedTime}
            key={selectedDate}
            onChange={(val) => handleChooseFlexibleDate(val)}
            options={availableTimes}
            className="custom_times_select"
            isDisabled={!selectedDate}
            // isMulti={selectedBookingType.value === "FLEXIBLE"}
          />
        </DateTimeInput>

        {selectedBookingType.value === "FLEXIBLE" && (
          <div className="booking_input">
            <span className="input_name">
              Flexible times (
              {selectedPackage?.info?.numOfLessons - flexibleDates.length})
            </span>
            <div className="flexible_times">
              {flexibleDates.map((el) => (
                <FlexTimeRow
                  date={el.label}
                  onDelete={() => onDeleteFlexDate(el)}
                />
              ))}
            </div>
          </div>
        )}

        {/* <div className="booking_input">
          <span className="input_name">Make Recurring</span>
          <div className="toggle-input" style={{ width: "100%" }}>
            <Toggle
              icons={false}
              className="custom_toggle"
              checked={isRecurring}
              onChange={(e) => {
                if (isRecurring) setIsRecurring(false);
                else setIsRecurring(true);
              }}
            />
          </div>
        </div> */}
      </div>
    </SharedModalContainer>
  );
};
const FlexTimeRow = ({ date, onDelete }) => {
  return (
    <div className="flex_time_row">
      <span>{date}</span>
      <span className="delete_time">
        <img src={del} alt="" onClick={onDelete} />
      </span>
    </div>
  );
};

export default CreateBookingModal;
