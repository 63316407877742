import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAdminBookingById,
  getPackages,
} from "../../../ReduxToolkit/Slices/adminSlice";
import "./adminBooking.styles.scss";
import moment from "moment";
import { ExchangeRateHook } from "../../../ExchangeRateHook";
const AdminBookingDetails = () => {
  const dispatch = useDispatch();
  const [booking, setBooking] = useState();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getAdminBookingById(id)).then((res) => {
        if (res.type.includes("fulfilled")) {
          setBooking({ ...res.payload, ...res.payload.adminBooking });
        }
      });
    }
  }, []);
  return (
    <div className="">
      {booking && (
        <div className="selected_admin_booking">
          <BookingDetails booking={booking} />
        </div>
      )}
    </div>
  );
};

export default AdminBookingDetails;

const StudentInfo = ({ student }) => (
  <div className="student-info">
    <h2 className="student-info__name">
      {student.user.name} {student.user.lastName}
    </h2>
    <p className="student-info__email">Email: {student.user.email}</p>
    <p className="student-info__phone">Phone: {student.user.phone}</p>
    <p className="student-info__country">
      Country: {student.user.address.country}
    </p>
    <p className="student-info__promo-codes">
      Promo Codes: {student.promoCodes.map((code) => code.code).join(", ")}
    </p>
  </div>
);
// LessonInfo.js
export const LessonInfo = ({ lesson }) => {
  return (
    <div className="lesson-info">
      <h2 className="lesson-info__title">{lesson.title}</h2>
      {/* <p className="lesson-info__description">
      Description: {lesson.description || "N/A"}
      </p> */}
      {/* <p className="lesson-info__online-price">
      Online Price: ${lesson.onlinePrice}
      </p>
      <p className="lesson-info__student-price">
      Student Place Price: ${lesson.studentPlacePrice}
      </p>
      <p className="lesson-info__teacher-price">
      Teacher Place Price: ${lesson.teacherPlacePrice}
      </p> */}
      <p className="lesson-info__level">Level: {lesson.level}</p>
      <p className="lesson-info__categories">
        Categories: {lesson.categories.map((cat) => cat.name).join(", ")}
      </p>
      <p className="lesson-info__subjects">
        Subjects: {lesson.subjects.map((sub) => sub.name).join(", ")}
      </p>
      <p className="lesson-info__subjects">
        Lesson Location: {lesson.location.map((loc) => loc).join(", ")}
      </p>
      {/* <h2 className="lesson-info__title">Prices</h2>
      <p>
        Online Price: <ExchangeRateHook price={lesson?.onlinePrice} />
      </p>
      {lesson?.studentPlacePrice ? (
        <p>
          Student Place Price:{" "}
          <ExchangeRateHook price={lesson?.studentPlacePrice} />
        </p>
      ) : (
        ""
      )}
      {lesson?.teacherPlacePrice ? (
        <p>
          Teacher Place Price:{" "}
          <ExchangeRateHook price={lesson?.teacherPlacePrice} />
        </p>
      ) : (
        ""
      )} */}
    </div>
  );
};
export const BookingInfo = ({ booking }) => {
  return (
    <div className="lesson-info">
      <h2 className="lesson-info__title">{booking?.lesson?.title}</h2>
      <p className="lesson-info__level">Location: {booking?.location}</p>
      <p className="lesson-info__categories">
        duration: {booking?.package?.duration} min
      </p>
      <p className="lesson-info__categories">
        Total price to pay: {<ExchangeRateHook price={booking?.total} />}{" "}
      </p>
    </div>
  );
};
export const TeacherInfo = ({ teacher }) => (
  <div className="teacher-info">
    <h2 className="teacher-info__name">
      {teacher.user.name} {teacher.user.lastName}
    </h2>
    <p className="teacher-info__email">Email: {teacher.user.email}</p>
    <p className="teacher-info__phone">Phone: {teacher.user.phone}</p>
    <p className="teacher-info__location">
      Location: {teacher.user.address.city}, {teacher.user.address.country}
    </p>
    <p className="teacher-info__hourly-rate">
      Hourly Rate: ${teacher.hourlyRate}
    </p>
    <p className="teacher-info__rating">Rating: {teacher.rating}</p>
    <p className="teacher-info__status">Status: {teacher.status}</p>
    <p className="teacher-info__">
      Available: {teacher.available ? "Yes" : "No"}
    </p>
  </div>
);

export const Appointments = ({ appointments }) => {
  const { timezone } = useSelector((state) => state.user);
  const utcToTimezone = (utcTime, targetTimezone) => {
    const localTime = moment
      .utc(utcTime)
      ?.tz(targetTimezone)
      ?.format("hh:mm A");
    return localTime;
  };
  const sortedAppointments = [...appointments].sort(
    (a, b) => new Date(a.startTime) - new Date(b.startTime)
  );

  return (
    <div className="appointments">
      <h3 className="appointments__header">Appointments</h3>
      <div className="appointments_container">
        {sortedAppointments.map((app) => (
          <div key={app.id} className="appointments__appointment">
            <h4 className="appointments__date">
              Date: {moment(app.date).format("DD/MM/YYYY")}
            </h4>
            <p className="appointments__start-time">
              Start: {utcToTimezone(app.startTime, timezone)}
            </p>
            {/* <p className="appointments__end-time">
              End: {new Date(app.endTime).toLocaleTimeString()}
            </p> */}
            <p className="appointments__status">Status: {app.status}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const BookingDetails = ({ booking }) => {
  return (
    <div className="booking-details">
      <section>
        <h2 className="section-title">Student Info</h2>
        <StudentInfo student={booking.student} />
      </section>
      <section>
        <h2 className="section-title">Booking Info</h2>
        <BookingInfo booking={booking} />
      </section>
      <section>
        <h2 className="section-title">Lesson Info</h2>
        <LessonInfo lesson={booking.lesson} />
      </section>

      <section>
        <h2 className="section-title">Teacher Info</h2>
        <TeacherInfo teacher={booking.lesson.teacher} />
      </section>

      <section>
        <h2 className="section-title">Appointments</h2>
        <Appointments appointments={booking.Appointments} />
      </section>
    </div>
  );
};
