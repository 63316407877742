
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

const ChatBot = () => {
    const showChat = () => {
        if (window.dfMessenger && window.dfMessenger.openChat) {
            window.dfMessenger.openChat();
        } else {
            console.error("dfMessenger is not initialized yet.");
        }
    };

    useEffect(() => {
        // Hide the default Dialogflow FAB
        const hideDfMessengerFab = () => {
            const dfMessengerButton = document.querySelector('df-messenger');
            if (dfMessengerButton && dfMessengerButton.shadowRoot) {
                const fabButton = dfMessengerButton.shadowRoot.querySelector('div[part="icon-button"]');
                if (fabButton) {
                    fabButton.style.display = 'none'; // Hide the default FAB
                }
            }
        };

        // Run the function at an interval to ensure the shadow DOM is loaded
        const interval = setInterval(hideDfMessengerFab, 500);
        return () => clearInterval(interval); // Clean up interval
    }, []);

    return (
        <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
            {/* The Dialogflow web messenger (hidden default FAB) */}
            <df-messenger
                intent="WELCOME"
                chat-title="Chat with Us!"
                agent-id="7d1323ad-973d-4546-bbe2-b836403619b3" // Replace with your actual agent ID
                language-code="en"
            ></df-messenger>

            {/* Custom message icon button */}
            <button onClick={showChat} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faComments} style={{ width: '50px', height: '50px', color: '#007bff' }} />
            </button>
        </div>
    );
};

export default ChatBot;
