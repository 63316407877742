import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentAdminBookings } from "../../../ReduxToolkit/Slices/studentSlice";
import moment from "moment-timezone";

import {
  Appointments,
  BookingInfo,
  LessonInfo,
  TeacherInfo,
} from "../../../AdminApp/Components/AdminBookingDetails/AdminBookingDetails";
import {
  deleteAdminBookingById,
  getAdminBookingById,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleAdminBookingLesson } from "../../../ReduxToolkit/Slices/lessonsSlice";

const StudentAdminBooking = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState();
  const [selectedBookings, setSelectedBookings] = useState();
  useEffect(() => {
    dispatch(getStudentAdminBookings()).then((res) => {
      if (res.type.includes("fulfilled")) {
        setBookings(res.payload);
      }
    });
  }, [refresh]);

  const onBookingCancel = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to cancel this booking?"
    );
    if (confirm) {
      dispatch(deleteAdminBookingById(id)).then((res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Booking deleted successfully");
          setRefresh(!refresh);
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };
  const { timezone } = useSelector((state) => state.user);
  function convertToTimezone(utcDate, timezone) {
    return moment.utc(utcDate).tz(timezone).format("DD/MM/YYYY hh:mm A");
  }
  const navigate = useNavigate();
  const onAccept = ({ id, studentId, row }) => {
    dispatch(
      handleAdminBookingLesson({
        lesson: row?.lesson?.id,
        lessonDetails: row?.lesson,
        teacher: row?.lesson?.teacher,
        lessonName: row?.lesson?.title,
        location: row?.location,

        dates: row.Appointments.map((el) =>
          convertToTimezone(el.startTime, timezone)
        ),
        booking: row,
      })
    );
    navigate(`/admin-booking/method/${studentId}/${id}`);
  };

  useEffect(() => {
    if (selectedBookings?.id) {
      setLoading(true);
      dispatch(getAdminBookingById(selectedBookings?.id)).then((res) => {
        if (res.type.includes("fulfilled")) {
          setSelectedBookings({ ...res.payload, ...res.payload.adminBooking });
          setLoading(false);
        }
      });
    }
  }, [selectedBookings?.id]);
  return (
    <div>
      <StudentBookingTable
        bookings={bookings}
        selectedBookings={selectedBookings}
        setSelectedBookings={setSelectedBookings}
        onBookingCancel={onBookingCancel}
        setRefresh={setRefresh}
        refresh={refresh}
        onAccept={onAccept}
        key={selectedBookings?.id}
      />
      {!!!loading && selectedBookings && (
        <div className="selected_admin_booking">
          <section>
            <h2 className="section-title">Lesson Info</h2>
            <LessonInfo lesson={selectedBookings.lesson} />
          </section>
          <section>
            <h2 className="section-title">Booking info</h2>
            <BookingInfo booking={selectedBookings} />
          </section>
          {/* <section>
            <h2 className="section-title">Teacher Info</h2>
            <TeacherInfo teacher={selectedBookings.lesson.teacher} />
          </section> */}

          <section>
            <h2 className="section-title">Appointments</h2>
            <Appointments appointments={selectedBookings.Appointments} />
          </section>
        </div>
      )}
    </div>
  );
};

const StudentBookingTable = ({
  bookings,
  selectedBookings,
  setSelectedBookings,
  onBookingCancel,
  setRefresh,
  refresh,
  onAccept,
}) => {
  return (
    <div className={`manage__students homeworks_table`}>
      <div className="header">
        <div className="header__text">
          <span style ={{
            fontSize: "32px",
          }}>Pending Invoices</span>
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "20px", // Increase font size
                  fontWeight: "bold", // Optional, to make it bold
                  textAlign: "left", // Optional, to align text left
                }}
              >
                Title
              </th>
              <th
                style={{
                  fontSize: "20px", // Increase font size
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Teacher
              </th>
              <th
                style={{
                  fontSize: "20px", // Increase font size
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {bookings?.length ? (
              bookings.map((row, i) => (
                <BookingRow
                  row={row}
                  key={i}
                  setSelectedBookings={setSelectedBookings}
                  onBookingCancel={onBookingCancel}
                  refresh={refresh}
                  onAccept={onAccept}
                />
              ))
            ) : (
              <p>No Admin Bookings</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const BookingRow = ({
  row,
  i,
  setSelectedBookings,
  onBookingCancel,
  setRefresh,
  refresh,
  onAccept,
}) => {
  return (
    <tr key={i}>
      <td>
        <span>{row?.lesson?.title}</span>
      </td>
      <td>
        <span>
          {row?.lesson?.teacher?.user?.name}{" "}
          {row?.lesson?.teacher?.user?.lastName}
        </span>
      </td>
      <td>
        <div className="gap custom_gap">
          
          <button
            style={{
              backgroundColor: "blue",
              color: "white",
              fontWeight: "bold",
              padding: "10px 20px",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              onAccept({ id: row.id, studentId: row?.student?.id, row });
            }}
          >
            Pay
          </button>
          <button
            style={{
              backgroundColor: "deepblue",
              color: "black",
              fontWeight: "bold",
              padding: "10px 20px", // Adds spacing inside the button
              fontSize: "16px", // Makes the text larger
              border: "none", // Removes default borders
              borderRadius: "5px", // Adds rounded corners (optional)
              cursor: "pointer", // Adds a pointer cursor on hover
            }}
            onClick={() => {
              setSelectedBookings(row);
            }}
          >
            View
          </button>
          {/*<button
            className="rejected"
            onClick={() => {
              onBookingCancel(row.id);
            }}
          >
            Cancel
          </button>*/}
        </div>
      </td>
    </tr>
  );
};
export default StudentAdminBooking;
