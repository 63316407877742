import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "./navbar.styles.scss";

import hamburger from "../../assets/HomeApp/svgs/menu.svg";
import Logo from "../../assets/HomeApp/images/logo.webp";
import searchsvg from "../../assets/HomeApp/svgs/searchicon.svg";
import search from "../../assets/HomeApp/svgs/search.svg";
import logo_mobile from "../../assets/HomeApp/images/logo_mobile.webp";
import cart from "../../assets/SharedApp/svgs/cart.svg";
import bell from "../../assets/SharedApp/svgs/bell.svg";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavCategories from "../../Sections/NavCategories/NavCategories";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

// import { categories } from "../../assets/HomeApp/data/navbar-Modal-data";
import { inputs } from "../../assets/HomeApp/data/navbar-data";
import { customStyles } from "../../assets/HomeApp/data/navbar-mobile";
import { styles } from "../../assets/HomeApp/data/navbar-mobile";
import { options } from "../../assets/HomeApp/data/navbar-mobile";
import { useEffect } from "react";
import { getCategoriesWithSubjects } from "../../ReduxToolkit/Slices/lessonsSlice";
import HoverCart from "../../SharedApp/Components/Courses/HoverCart/HoverCart";
import {
  getCartItems,
  getCourseCategories,
  handleCoursesStateChange,
} from "../../ReduxToolkit/Slices/coursesSlice";
import DiscountBar, {
  BetaBar,
} from "../../AdminApp/Components/DiscountBar/DiscountBar";
import { getPublicSettings } from "../../ReduxToolkit/Slices/userSlice";
import { setAdminState } from "../../ReduxToolkit/Slices/adminSlice";

export default function Navbar({ coursesPosition }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [hoverCart, setHoverCart] = useState(false);
  const [searchValue, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [discount, setDiscount] = useState();
  const [lessonDiscount, setLessonDiscount] = useState();

  const { user, logout } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.student);
  const { refreshCart } = useSelector((state) => state.courses);
  const { cartItems } = useSelector((state) => state.student);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesWithSubjects()).then((res) => {
      setCategories(res?.payload?.data?.categories);
    });
    dispatch(getPublicSettings()).then((res) => {
      res?.payload?.adminSetting &&
        dispatch(
          setAdminState({
            name: "publicSetting",
            value: res?.payload?.adminSetting,
          })
        );
      setLessonDiscount(res?.payload?.adminSetting?.lessonDiscount);
      setDiscount(res?.payload?.adminSetting?.courseDiscount);
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // courses function
  // useEffect(() => {
  //   user.role === "STUDENT" &&
  //     dispatch(getCartItems(student)).then((res) => {
  //
  //       setCourses(res?.payload?.cartItems);
  //       setTotal(res?.payload?.total);
  //       dispatch(
  //         handleCoursesStateChange({
  //           name: "cartItems",
  //           value: res.payload.cartItems,
  //         })
  //       );
  //     });
  // }, [refreshCart]);

  const [page1, setPage1] = useState(true);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const onMouseEnter = () => {
    if (!openMenu) {
      setOpenMenu(true);
    }
  };

  useEffect(() => {
    const scriptSrc =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    // Check if the script already exists
    if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
      // If not, create and append the script
      let addScript = document.createElement("script");
      addScript.setAttribute("src", scriptSrc);
      document.body.appendChild(addScript);
    }
  }, []);
  const showDiscountBar =
    isVisible &&
    discount &&
    discount > 0 &&
    !location.pathname.includes("tutors");

  // useEffect(() => {
  //   //showDiscountBar to global state
  // }, []);

  const [exploreCourses, setExploreCourses] = useState([]);
  // const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes("courses"))
      dispatch(getCourseCategories()).then((res) => {
        setExploreCourses(res?.payload?.data);
      });
  }, [location.pathname]);
  return (
    <div>
      {!showDiscountBar ? null : coursesPosition ? (
        <DiscountBar discount={discount} />
      ) : (
        <DiscountBar discount={lessonDiscount} lesson />
      )}
      <nav
        className={`navbar__container ${isVisible ? "svisible" : "shidden"}`}
      >
        <div
          className={`navbar__mobile ${openNav ? "navbar__mobile__active" : ""}
          `}
        >
          {page1 && (
            <NavLanding
              setSelectedCategory={setSelectedCategory}
              page1={page1}
              setPage1={setPage1}
              selectedCategory={selectedCategory}
              categories={categories}
              logout={logout}
              user={user}
            />
          )}
          {!page1 && categories.length > 0 && (
            <GetCategories
              category={selectedCategory}
              setPage1={setPage1}
              page1={page1}
              categories={categories}
            />
          )}
        </div>
        <div className="navbar__menu mobile">
          <img src={hamburger} alt="" onClick={() => setOpenNav(!openNav)} />
          <div
            className={`close__navbar ${
              openNav ? "close__navbar__active" : ""
            }`}
            onClick={() => setOpenNav(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="navbar__logo">
          <Link to={"/"}>
            <img src={Logo} alt="logo" />
          </Link>
          <Link to="/">
            <img src={Logo} alt="logo" className="logo__mobile" />
          </Link>
        </div>
        {/* <div className="navbar__menu mobile">
        {!logout && user.role === "STUDENT" && (
          <div className="heart">
            <Link to={"/favorites"}>
              {" "}
              <FavoriteBorderIcon style={{ color: "#fff" }} />
            </Link>
          </div>
        )}
        <img src={search} alt="" className="mobile" />
      </div> */}
        <div className={`navbar__links`}>
          {!location.pathname.includes("tutors") && (
            <div className="nav__dropdown">
              <button
                className="primary"
                onClick={() => toggleMenu()}
                onMouseOver={() => onMouseEnter()}
              >
                {inputs.primary}
                <ExpandMoreIcon
                  className={`${openMenu && "btn__chevron__rotate"}`}
                />
              </button>
              {openMenu && categories?.length > 0 && (
                <div className={`nav__menu  active fix__nav `}>
                  <NavCategories
                    categories={
                      location.pathname.includes("courses")
                        ? exploreCourses
                        : categories
                    }
                    setOpenMenu={setOpenMenu}
                    isCourses={location.pathname.includes("courses")}
                  />
                </div>
              )}
            </div>
          )}
          <form
            className="nav__search"
            style={{
              opacity: !location.pathname.includes("tutors/search") ? 1 : 0,
            }}
            onSubmit={() => navigate(`/tutors/search/${searchValue}`)}
          >
            <img src={searchsvg} alt="" />
            <input
              type="text"
              placeholder={inputs.search}
              value={searchValue}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
          

          {!logout && user?.role === "STUDENT" && (
            <div className="heart">
              <Link to={"/favorites"}>
                <FavoriteBorderIcon style={{ color: "#fff" }} />
                {/* <p>2</p> */}
              </Link>
            </div>
          )}
          {!logout && user?.role === "STUDENT" && (
            <div className="action__icons">
              <div className="item1" onMouseOver={(e) => setHoverCart(true)}>
                <Link to={"/cart"}>
                  <span className="cart_number flex_center">
                    {courses?.length + cartItems?.length}{" "}
                  </span>
                  <img className="cart__ico" src={cart} alt="" />
                </Link>
                {hoverCart && (
                  <HoverCart
                    onMouseOut={() => setHoverCart(false)}
                    courses={courses}
                    total={total}
                  />
                )}
              </div>
            </div>
          )}
          {logout ? (
            <Link className="teacher__btn flex_center" to={"/teacher"}>
              {inputs.teacher}
            </Link>
          ) : (
            <div style={{ width: "50px" }} />
          )}
          {/* <div id="google_translate_element"> </div> */}
          <div id="google_translate_element"></div>

          {!logout ? (
            <Link
              className="nav__login flex_center"
              to={
                user?.role === "BLOGADMIN"
                  ? "/blogadmin"
                  : user.role === "STUDENT"
                  ? "/students"
                  : user.role === "TEACHER"
                  ? "/teachers"
                  : "/admin"
              }
            >
              Logged In
            </Link>
          ) : (
            <Link className="nav__login flex_center" to={"/login"}>
              {inputs.login}
            </Link>
          )}
        </div>
        {/* Add the "Free Trial" button */}
        <button
          onClick={() => navigate("/free-trial")}
          style={{
            backgroundColor: "#FFD700", // Deep yellow color
            color: "#000", // White text
            padding: "10px 20px", // Adjust padding for better fit
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "18px", // Increased font size for larger text
            cursor: "pointer",
            marginTop: "5px",
            textAlign: "center", // Center-align text
            width: "200px", // Set a fixed width for consistency
          }}
        >
          Book a Free Trial
        </button>
      </nav>
    </div>
  );
}

const NavLanding = ({
  setSelectedCategory,
  setPage1,
  page1,
  categories,
  selectedCategory,
  logout,
  user,
}) => {
  const navigate = useNavigate();
  const [searchValue, setSearch] = useState("");
  return (
    <div className="our__lessons">
      <form
        className="nav__search__mobile__nav"
        onSubmit={() => navigate(`/tutors/search/${searchValue}/`)}
      >
        <img src={searchsvg} alt="" />
        <input
          type="text"
          placeholder={inputs.search}
          value={searchValue}
          onChange={(e) => setSearch(e.target.value)}
        />
      </form>
      {logout && (
        <div className="nav__teacher">
          <Link to={"/teacher"}>
            <button className="teacher__btn__mobile"> {inputs.teacher}</button>
          </Link>
        </div>
      )}
      <div className="user__nav__icons">
        {!logout ? (
          <Link
            className="nav__login__mobile"
            to={
              user.role === "STUDENT"
                ? "/students"
                : user.role === "TEACHER"
                ? "/teachers"
                : "/admin"
            }
          >
            Dashboard
          </Link>
        ) : (
          <div className="login">
            <Link to={"/login"}>
              <span>Log In</span>
            </Link>
          </div>
        )}

        {!logout && user.role === "STUDENT" && (
          <div className="heart">
            <Link to={"/favorites"}>
              <FavoriteBorderIcon style={{ color: "#fff" }} />
              {/* <p>2</p> */}
            </Link>
          </div>
        )}
        {!logout && user.role === "STUDENT" && (
          <div className="action__icons">
            <div className="item1">
              <Link to={"/cart"}>
                <img className="cart__ico" src={cart} alt="" />
              </Link>
            </div>
          </div>
        )}
      </div>
      {/* <div id="google_translate_element"> </div> */}

      <h3>Our Lessons</h3>
      <div className="nav__categories__mobile">
        {categories &&
          categories.map((cat, i) => (
            <div
              key={i}
              className="nav__category"
              onClick={() => {
                setSelectedCategory(cat.name);
                setPage1(!page1);
              }}
            >
              <span>{cat.name}</span>
              <ChevronRightIcon />
            </div>
          ))}
      </div>
      {/* <GetCategories
        category={selectedCategory}
        setPage1={setPage1}
        page1={page1}
        categories={categories}
      /> */}

      <div className="other">
        <select name="currency" id="nav__currency">
          <option value="euro">Euro</option>
          <option value="usd">USD</option>
        </select>
      </div>
    </div>
  );
};

const GetCategories = ({ category, setPage1, page1, categories }) => {
  const found = categories.find((el) => el.name === category);
  return (
    <div className="nav__category__details">
      <div className="back">
        <span onClick={() => setPage1(!page1)}>
          <ChevronLeftIcon /> Menu
        </span>
      </div>
      <div className="nav__category__list">
        <h3>{found.name}</h3>
        {found &&
          found.subjects.map((sub, i) => (
            <>
              <span key={i}>
                <Link to={`/tutors/search/${sub.name}/${category}`}>
                  {sub.name}
                </Link>
              </span>{" "}
            </>
          ))}
        <span className="cat__seeall">See All</span>
      </div>
    </div>
  );
};

// const HoverCart = () => {
//   return (
//     <div className="hover__cart">
//       <div className="faved__courses">
//         <CartCourse />
//         <CartCourse />
//         <CartCourse />
//         <CartCourse />
//         <CartCourse />
//       </div>
//       <div className="cart__btn">
//         <button className="gold__lg">Go to Wishlist</button>
//       </div>
//     </div>
//   );
// };

// const CartCourse = () => {
//   return (
//     <div className="faved__course">
//       <img src={cours} alt="" />
//       <div className="course__details">
//         <h4>How to Budget and Forecast for your Business </h4>
//         <p>Milner Jones</p>
//         <span>$28.00</span>
//       </div>
//     </div>
//   );
// };
